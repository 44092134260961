<template>
  <div
    id="services-container"
    class="max-w-[1440px] w-full mx-auto flex flex-wrap justify-center gap-4"
  >
    <DefineServiceCard v-slot="{ image, imageSet, title, content, link }">
      <a
        :href="link"
        class="group flex flex-col items-center max-md:min-w-[120px] max-lg:min-w-[180px] min-w-[250px] max-w-[275px] text-neutral"
      >
        <img
          :src="image"
          :srcset="imageSet"
          loading="lazy"
          alt="Your image"
          class="w-[90px] h-[90px] mx-auto object-cover object-center mb-2"
          width="90"
          height="90"
        />
        <h3
          class="w-full text-xl max-md:text-base max-lg:text-lg text-center leading-7 font-bold mb-4"
        >
          {{ title }}
        </h3>
        <p class="w-full max-md:text-sm text-base text-center leading-5 px-2">
          {{ content }}
        </p>
      </a>
    </DefineServiceCard>
    <Splide
      v-if="inMobile"
      :options="{
        focus: false,
        perMove: 2,
        drag: 'free',
        fixedWidth: '240px',
        gap: '16px',
        pagination: false,
        trimSpace: 'move',
        omitEnd: true,
        i18n: {
          slide: ''
        },
        arrows: false
      }"
      class="w-full splide-center"
    >
      <SplideSlide
        v-for="(item, index) in processedItems"
        :key="index"
        class="pt-[1px] pb-2 px-[1px]"
      >
        <ServiceCard
          v-bind="item"
          class="min-h-[325px] rounded-lg shadow-lg ring-1 ring-line/25 px-2 py-4"
        ></ServiceCard>
      </SplideSlide>
    </Splide>
    <template v-else>
      <ServiceCard
        v-for="(item, index) in processedItems"
        :key="index"
        v-bind="item"
      ></ServiceCard>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import { createReusableTemplate } from '@vueuse/core';

const [DefineServiceCard, ServiceCard] = createReusableTemplate<{
  image: string;
  imageSet: string;
  title: string;
  content: string;
  link: string;
}>();

interface Service {
  icon: string;
  title: string;
  content: string;
  link: string;
}

const props = defineProps({
  items: {
    type: Array as PropType<Service[]>,
    default: () => []
  }
});

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const inMobile = ref(true);

const altHost = 'https://pfsrealty.com';

const processedItems = computed(() => {
  return props.items.map(item => {
    const image = `${cfUrl}/image?w=90&h=90&f=webp&fit=scale-down&image=${altHost}${item.icon}`;
    return {
      ...item,
      image,
      imageSet: `${image} 1x, ${cfUrl}/image?w=180&h=180&f=webp&fit=scale-down&image=${altHost}${item.icon} 2x`
    };
  });
});

const checkWidth = () => {
  inMobile.value = window.innerWidth < 800;
};

onMounted(() => {
  checkWidth();
  window.addEventListener('resize', checkWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkWidth);
});
</script>
